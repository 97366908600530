import { HomeArticle } from "components/Homepage/HomeArticle.tsx";
import { HomeSection } from "components/Homepage/HomeSection.tsx";
import { Headline } from "components/UI/Headline";
import { ClintButton } from "components/shadcn-ui/button";

const TryDechetterie = () => {
  return (
    <HomeSection color="transparent">
      <HomeArticle color="transparent">
        <div className="flex flex-col items-center justify-center gap-5 lg:gap-[2.625rem]">
          <Headline size="h3" as="div" className="text-center">
            {`Vous transportez vos propres déchets ?`}
          </Headline>
          <ClintButton
            as="Link"
            variant="primary"
            linkProps={{
              to: `/dechetterie`,
              prefetch: "intent",
            }}
          >
            Trouvez une déchetterie
          </ClintButton>
        </div>
      </HomeArticle>
    </HomeSection>
  );
};

export default TryDechetterie;
